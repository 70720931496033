<template>
  <header class="page-header">
    <v-toolbar
      :dark="!!dark"
      flat
      color="transparent"
      height="92px"
      class="pa-0"
    >
      <v-btn
        :dark="!!dark"
        v-if="back"
        icon
        class="back-btn ma-0 mr-2"
        @click="$router.go(-1)"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div :class="['page-header-content d-flex flex-column', !!$slots.nav ? 'justify-end' : 'justify-center']">
        <div class="page-header-subtitle" v-if="subtitle"><span>{{subtitle}}</span></div>
        <div class="page-header-title text-h5 text-capitalize mb-2">
          <v-icon
            :dark="!!dark"
            size="24"
            class="mr-2 mb-1"
            :color="!!dark ? 'white' : 'primary'"
            v-if="icon"
          >
            {{ icon }}
          </v-icon>
          <span>{{ title }}</span>
        </div>
        <div class="page-header-menu" v-if="!!$slots.nav"><slot name="nav"/></div>
      </div>
      <v-spacer />
      <slot/>
    </v-toolbar>
    <div class="toolbar-separator primary ml-4 mr-4 page-header-separator"/>
  </header>
</template>

<style>
.page-header .v-toolbar__content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.page-header-subtitle {
  margin-top: 10px;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.page-header-subtitle + .page-header-title {
  line-height: 32px !important;
}

.page-header-content {
  height: 100%;
  margin-bottom: -4px;
  margin-top: -4px;
}
.page-header-title {
  line-height: 32px !important;
}

.page-header-separator {
  margin-top: -2px;
}

</style>

<script>
export default {
  name: 'PageHeader',
  props: ['icon', 'title', 'subtitle', 'count', 'back', 'dark'],
}
</script>
